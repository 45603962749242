<!-- 三方API設定-->
<template>
  <div>
    <b-row v-show="cusNo!=null">
      <b-col cols="12">
        <b-card title="蝦皮訂單自動化全開立拍賣賣場授權(授權完成關閉視窗即可)">
          <div>
            <b-row>
              <b-col cols="3">
                <span style="color:white;background-color:red;">測試區</span> 請輸入開立發票的賣家統編
              </b-col>
              <b-col cols="3">
                統編:
                <b-form-input v-model="confirmCusNo" :disabled="step == 2"></b-form-input>
              </b-col>
              <b-col cols="3">
                密碼:
                <b-form-input type="password" v-model="pwd" :disabled="step == 2"></b-form-input>
              </b-col>
              <b-col cols="3" v-show="step == 1">
                <b-button variant="warning"  @click="nextStep">確定</b-button>
              </b-col>
            </b-row>
          </div>
          <b-form @submit.prevent>
            <h2 style="color:red;">依照蝦皮授權規範個資保護法，系統於每天03:00拋轉至財政部發票系統開立後，訂單資料並無保留此平台，此平台不提供查詢歷史訂單服務，開立結果請至財政部電子發票平台查詢。</h2>
            <b-row v-show="step==2">
              <b-col cols="12">
                <b-form-group label="主賣場" label-for="h-first-name" label-cols-md="4">
                  <table>
                    <tbody>
                      <tr>
                        <td>{{sp1.accessDate != ''? '授權截止時間(為期一年)':'尚未設定授權'}}:</td>
                        <td>{{sp1.accessDate}}</td>
                      </tr>
                      <tr>
                        <td colspan="2">
                          <b-button variant="warning" class="mr-1" @click="getShoppeeAuthUrl('API_SP1')">主賣場{{sp1.accessDate == ''? '設定授權':'重新授權'}}</b-button>
                          <br/>
                          <!-- <b-button v-show="sp1.accessDate != ''" variant="primary" class="mt-1"   @click="refreshAcToekn('API_SP1')">更新/延長授權時間</b-button> -->
                          <!-- <br/> -->
                          <b-button v-show="sp1.accessDate != ''" variant="danger" class="mt-1"   @click="deleteSpToken(sp1.shopId)">停止授權</b-button>
                        </td>
                      </tr>
                    </tbody>
                  </table>                  
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="賣場(二)" label-for="h-first-name" label-cols-md="4">
                  <table>
                    <tbody>
                      <tr>
                        <td>{{sp2.accessDate != ''? '授權截止時間(為期一年)':'尚未設定授權'}}:</td>
                        <td>{{sp2.accessDate}}</td>
                      </tr>
                      <tr>
                        <td colspan="2">
                          <b-button variant="warning" class="mr-1" @click="getShoppeeAuthUrl('API_SP2')">賣場(二){{sp2.accessDate == ''? '設定授權':'重新授權'}}</b-button>
                          <br/>
                          <!-- <b-button v-show="sp2.accessDate != ''" variant="primary" class="mt-1" @click="refreshAcToekn('API_SP2')">更新/延長授權時間</b-button>
                          <br/> -->
                          <b-button v-show="sp2.accessDate != ''" variant="danger" class="mt-1"   @click="deleteSpToken(sp2.shopId)">停止授權</b-button>
                        </td>
                      </tr>
                    </tbody>
                  </table>                  
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="賣場(三)" label-for="h-first-name" label-cols-md="4">
                  <table>
                    <tbody>
                      <tr>
                        <td>{{sp3.accessDate != ''? '授權截止時間(為期一年)':'尚未設定授權'}}:</td>
                        <td>{{sp3.accessDate}}</td>
                      </tr>
                      <tr>
                        <td colspan="2">
                          <b-button variant="warning" class="mr-1" @click="getShoppeeAuthUrl('API_SP3')">賣場(三){{sp3.accessDate == ''? '設定授權':'重新授權'}}</b-button>
                          <br/>
                          <b-button v-show="sp3.accessDate != ''" variant="danger" class="mt-1"   @click="deleteSpToken(sp3.shopId)">停止授權</b-button>
                        </td>
                      </tr>
                    </tbody>
                  </table>                  
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group label="賣場(四)" label-for="h-first-name" label-cols-md="4">
                  <table>
                    <tbody>
                      <tr>
                        <td>{{sp4.accessDate != ''? '授權截止時間(為期一年)':'尚未設定授權'}}:</td>
                        <td>{{sp4.accessDate}}</td>
                      </tr>
                      <tr>
                        <td colspan="2">
                          <b-button variant="warning" class="mr-1" @click="getShoppeeAuthUrl('API_SP4')">賣場(四){{sp4.accessDate == ''? '設定授權':'重新授權'}}</b-button>
                          <br/>
                          <b-button v-show="sp4.accessDate != ''" variant="danger" class="mt-1"   @click="deleteSpToken(sp4.shopId)">停止授權</b-button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group label="賣場(五)" label-for="h-first-name" label-cols-md="4">
                  <table>
                    <tbody>
                      <tr>
                        <td>{{sp5.accessDate != ''? '授權截止時間(為期一年)':'尚未設定授權'}}:</td>
                        <td>{{sp5.accessDate}}</td>
                      </tr>
                      <tr>
                        <td colspan="2">
                          <b-button variant="warning" class="mr-1" @click="getShoppeeAuthUrl('API_SP5')">賣場(五){{sp5.accessDate == ''? '設定授權':'重新授權'}}</b-button>
                          <br/>
                          <b-button v-show="sp5.accessDate != ''" variant="danger" class="mt-1"   @click="deleteSpToken(sp5.shopId)">停止授權</b-button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group label="賣場(六)" label-for="h-first-name" label-cols-md="4">
                  <table>
                    <tbody>
                      <tr>
                        <td>{{sp6.accessDate != ''? '授權截止時間(為期一年)':'尚未設定授權'}}:</td>
                        <td>{{sp6.accessDate}}</td>
                      </tr>
                      <tr>
                        <td colspan="2">
                          <b-button variant="warning" class="mr-1" @click="getShoppeeAuthUrl('API_sp6')">賣場(六){{sp6.accessDate == ''? '設定授權':'重新授權'}}</b-button>
                          <br/>
                          <b-button v-show="sp6.accessDate != ''" variant="danger" class="mt-1"   @click="deleteSpToken(sp6.shopId)">停止授權</b-button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group label="賣場(七)" label-for="h-first-name" label-cols-md="4">
                  <table>
                    <tbody>
                      <tr>
                        <td>{{sp7.accessDate != ''? '授權截止時間(為期一年)':'尚未設定授權'}}:</td>
                        <td>{{sp7.accessDate}}</td>
                      </tr>
                      <tr>
                        <td colspan="2">
                          <b-button variant="warning" class="mr-1" @click="getShoppeeAuthUrl('API_sp7')">賣場(七){{sp7.accessDate == ''? '設定授權':'重新授權'}}</b-button>
                          <br/>
                          <b-button v-show="sp7.accessDate != ''" variant="danger" class="mt-1"   @click="deleteSpToken(sp7.shopId)">停止授權</b-button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group label="賣場(八)" label-for="h-first-name" label-cols-md="4">
                  <table>
                    <tbody>
                      <tr>
                        <td>{{sp8.accessDate != ''? '授權截止時間(為期一年)':'尚未設定授權'}}:</td>
                        <td>{{sp8.accessDate}}</td>
                      </tr>
                      <tr>
                        <td colspan="2">
                          <b-button variant="warning" class="mr-1" @click="getShoppeeAuthUrl('API_sp8')">賣場(八){{sp8.accessDate == ''? '設定授權':'重新授權'}}</b-button>
                          <br/>
                          <b-button v-show="sp8.accessDate != ''" variant="danger" class="mt-1"   @click="deleteSpToken(sp8.shopId)">停止授權</b-button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group label="賣場(九)" label-for="h-first-name" label-cols-md="4">
                  <table>
                    <tbody>
                      <tr>
                        <td>{{sp9.accessDate != ''? '授權截止時間(為期一年)':'尚未設定授權'}}:</td>
                        <td>{{sp9.accessDate}}</td>
                      </tr>
                      <tr>
                        <td colspan="2">
                          <b-button variant="warning" class="mr-1" @click="getShoppeeAuthUrl('API_sp9')">賣場(九){{sp9.accessDate == ''? '設定授權':'重新授權'}}</b-button>
                          <br/>
                          <b-button v-show="sp9.accessDate != ''" variant="danger" class="mt-1"   @click="deleteSpToken(sp9.shopId)">停止授權</b-button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group label="賣場(十)" label-for="h-first-name" label-cols-md="4">
                  <table>
                    <tbody>
                      <tr>
                        <td>{{sp10.accessDate != ''? '授權截止時間(為期一年)':'尚未設定授權'}}:</td>
                        <td>{{sp10.accessDate}}</td>
                      </tr>
                      <tr>
                        <td colspan="2">
                          <b-button variant="warning" class="mr-1" @click="getShoppeeAuthUrl('API_sp10')">賣場(十){{sp10.accessDate == ''? '設定授權':'重新授權'}}</b-button>
                          <br/>
                          <b-button v-show="sp10.accessDate != ''" variant="danger" class="mt-1"   @click="deleteSpToken(sp10.shopId)">停止授權</b-button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
  <script>
import moment from 'moment'
import flatPickr from 'vue-flatpickr-component'
import {
  BForm,BCard, BCardText, BCol, BRow, BFormGroup, BFormInput, BButton,BTable ,BFormSelect,BPagination,BTableSimple,BTr,BTd,BTfoot,BTh,BTbody,BThead,BTabs, BTab,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
  
  export default {
    components: {
      BCol,
      BRow,
      BCard,
      BCardText,
      BFormGroup,
      BFormInput,
      BButton,BTable ,BFormSelect,BPagination,BForm,
      vSelect,
      flatPickr,
    },
    data() {
      return {
        pwd:'',
        confirmCusNo:'',
        step:1,
        cusNo:'',
        sp1:{accessDate:'', shopId:''},
        sp2:{accessDate:'', shopId:''},
        sp3:{accessDate:'', shopId:''},
        sp4:{accessDate:'', shopId:''},
        sp5:{accessDate:'', shopId:''},
        sp6:{accessDate:'', shopId:''},
        sp7:{accessDate:'', shopId:''},
        sp8:{accessDate:'', shopId:''},
        sp9:{accessDate:'', shopId:''},
        sp10:{accessDate:'', shopId:''},
      }
    },
    async created() {
      //
      // 先請客戶輸入自己的統編
      // 新增賣場
      // 新增賣場
      // 更新sp_api_token
      // acToken rfToken cusNo fromSalestore platformId tokenDate shopId partnerId  createDate
      // 每10分鐘就要去檢查token是否要過期了
      // 要去撈order // 要去更新order
      this.cusNo = this.$route.query.cusNo
      if(this.$route.query.cusNo == null){
        this.$toast({ component: ToastificationContent,
            props: {title: '缺少統編資訊請洽管理員', variant: 'danger', autoHideDelay: 5000, icon:'none'},
          })
      }

      if(this.$route.query.code!=undefined){
        this.step = 2
        let code = this.$route.query.code
        let shopId = this.$route.query.shop_id
        let cusCombineCode = this.$route.query.cat
        // cusCombineCode 就是 cusNo + platformId 的組合key
        let ss = cusCombineCode.split('-')
        this.cusNo = ss[0]
        this.confirmCusNo = this.cusNo
        this.platformId = ss [1]
        let params = {code,shopId,cusCombineCode}
        //此欄位都要記錄起來 並開始取得AccessCode
        let res = await this.$http.post('../api/invoicePlatformApiShopeeAuthUat/updateShopeeCode', params)
        window.history.replaceState(null, null, window.location.pathname);
        // 更新以後 把query param 的data 要清空
        this.$route.query.cusNo = this.cusNo
        if(res.data.msg == 'success'){
          this.$toast({ component: ToastificationContent,
            props: {title: '授權成功' + res.data.data, variant: 'success', autoHideDelay: 2000, icon:'none'},
          })
          this.refreshAcToekn(shopId, cusCombineCode)
        } else{
          this.$toast({ component: ToastificationContent,
            props: {title: '授權失敗', variant: 'danger', autoHideDelay: 5000, icon:'none'},
          })
        }
        this.getApiSetting()
      }
    },
    methods: {
      // 檢查 step 是否正常
      nextStep(){
        if(this.confirmCusNo == this.cusNo && this.confirmCusNo != '') {
          let params = {cusNo:this.confirmCusNo, pwd:this.pwd}
          this.$http.post('../api/invoicePlatformApiShopeeAuthUat/login', params).then(res => {
            if(res.data.msg == 'success') {
              this.getApiSetting()
              this.step = 2
            } else {
              this.$toast({ component: ToastificationContent,
                props: {title: '登入失敗'+res.data.data, variant: 'danger', autoHideDelay: 5000, icon:'none'},
              })
            }
          })
        } else {
          this.$toast({ component: ToastificationContent,
            props: {title: '統編不對，無法進行確認，請洽管理員', variant: 'danger', autoHideDelay: 5000, icon:'none'},
          })
        }
      },
      deleteSpToken(shopId){
        let params = {shopId}
        this.$http.post('../api/invoicePlatformApiShopeeAuthUat/deleteSpToken', params).then(res => {
          if(res.data.msg == 'success') {
            this.$toast({ component: ToastificationContent,
              props: {title: '清空成功', variant: 'success', autoHideDelay: 2000, icon:'none'},
            })
            this.getApiSetting()
          } else {
            this.$toast({ component: ToastificationContent,
              props: {title: '清空失敗', variant: 'danger', autoHideDelay: 5000, icon:'none'},
            })
          }
        })
      },
      getApiSetting(){
        let params = {cusNo: this.cusNo}
        
        this.$http.get('../api/invoicePlatformApiShopeeAuthUat/getApiSetting', {params}).then(res => {
          if(res.data.msg == 'success'){
            this.sp1.accessDate = ''
            this.sp2.accessDate = ''
            this.sp3.accessDate = ''
            this.sp4.accessDate = ''
            this.sp5.accessDate = ''
            this.sp6.accessDate = ''
            this.sp7.accessDate = ''
            this.sp8.accessDate = ''
            this.sp9.accessDate = ''
            this.sp10.accessDate = ''
            if(res.data.data == null) {

            } else {
              res.data.data.forEach(e => {
                if(e.platformId == 'API_SP1') {
                  let a = e.updateDate
                  this.sp1.shopId = e.shopId
                  this.sp1.accessDate = moment(a,'YYYYMMDDhhmmss').format('YYYY-MM-DD hh:mm:ss')
                } else if(e.platformId == 'API_SP2') {
                  let a = e.updateDate
                  this.sp2.shopId = e.shopId
                  this.sp2.accessDate = moment(a,'YYYYMMDDhhmmss').format('YYYY-MM-DD hh:mm:ss')
                } else if(e.platformId == 'API_SP3') {
                  let a = e.updateDate
                  this.sp3.shopId = e.shopId
                  this.sp3.accessDate = moment(a,'YYYYMMDDhhmmss').format('YYYY-MM-DD hh:mm:ss')
                } else if(e.platformId == 'API_SP4') {
                  let a = e.updateDate
                  this.sp4.shopId = e.shopId
                  this.sp4.accessDate = moment(a,'YYYYMMDDhhmmss').format('YYYY-MM-DD hh:mm:ss')
                } else if(e.platformId == 'API_SP5') {
                  let a = e.updateDate
                  this.sp5.shopId = e.shopId
                  this.sp5.accessDate = moment(a,'YYYYMMDDhhmmss').format('YYYY-MM-DD hh:mm:ss')
                } else if(e.platformId == 'API_SP6') {
                  let a = e.updateDate
                  this.sp6.shopId = e.shopId
                  this.sp6.accessDate = moment(a,'YYYYMMDDhhmmss').format('YYYY-MM-DD hh:mm:ss')
                } else if(e.platformId == 'API_SP7') {
                  let a = e.updateDate
                  this.sp7.shopId = e.shopId
                  this.sp7.accessDate = moment(a,'YYYYMMDDhhmmss').format('YYYY-MM-DD hh:mm:ss')
                } else if(e.platformId == 'API_SP8') {
                  let a = e.updateDate
                  this.sp8.shopId = e.shopId
                  this.sp8.accessDate = moment(a,'YYYYMMDDhhmmss').format('YYYY-MM-DD hh:mm:ss')
                } else if(e.platformId == 'API_SP9') {
                  let a = e.updateDate
                  this.sp9.shopId = e.shopId
                  this.sp9.accessDate = moment(a,'YYYYMMDDhhmmss').format('YYYY-MM-DD hh:mm:ss')
                } else if(e.platformId == 'API_SP10') {
                  let a = e.updateDate
                  this.sp10.shopId = e.shopId
                  this.sp10.accessDate = moment(a,'YYYYMMDDhhmmss').format('YYYY-MM-DD hh:mm:ss')
                }
              })
            }
          } else{
            this.$toast({ component: ToastificationContent,
              props: {title: '資料取得失敗', variant: 'danger', autoHideDelay: 5000, icon:'none'},
            })
          }
        })
      },
      refreshAcToekn(shopId, cusCombineCode){
        let params = {shopId,cusCombineCode}
        this.$http.get('../api/invoicePlatformApiShopeeAuthUat/refreshAcToekn', {params}).then(res => {
          if(res.data.msg == 'success'){
            this.$toast({ component: ToastificationContent,
              props: {title: '更新成功', variant: 'success', autoHideDelay: 2000, icon:'none'},
            })
            this.getApiSetting()
          } else{

          }
        })
      },
      getShoppeeAuthUrl(platformId){
        // 組成cusCombineCode
        let params = {cusCombineCode: `${this.cusNo}-${platformId}`}
        this.$http.get('../api/invoicePlatformApiShopeeAuthUat/getShoppeeAuthUrl', {params}).then(res => {
          if(res.data.msg == 'success'){
            const link = document.createElement('a');
            link.href = res.data.data.sign;
            document.body.appendChild(link);
            link.click();
          } else{

          }
        })
      },
    },
  }
  
  </script>
  
  <style lang="scss" >
  @import '~@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-good-table.scss';
  @import '@/assets/scss/style.scss';
  
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  </style>
  